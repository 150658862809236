import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import uuidv4 from '../../utils/uuidv4';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  board: {
    items: {},
    areas: {},
    areaOrder: [],
  },
};

const slice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET QUOTATION
    getQuotationSuccess(state, action) {
      state.isLoading = false;
      const quotation = action.payload;

      const items = keyBy(quotation.items, 'id');
      const areas = keyBy(quotation.areas, 'id');

      state.quotation = {
        items,
        areas,
        areaOrder: quotation.areaOrder,
      };
    },

    // CREATE NEW AREA
    createAreaSuccess(state, action) {
      const newArea = action.payload;
      state.isLoading = false;
      state.quotation.areas = {
        ...state.quotation.areas,
        [newArea.id]: newArea,
      };
      state.quotation.areaOrder.push(newArea.id);
    },

    persistItem(state, action) {
      const areas = action.payload;
      state.quotation.areas = areas;
    },

    persistArea(state, action) {
      state.quotation.areaOrder = action.payload;
    },

    addItem(state, action) {
      const { item, areaId } = action.payload;

      state.quotation.items[item.id] = item;
      state.quotation.areas[areaId].items.push(item.id);
    },

    deleteItem(state, action) {
      const { itemId, areaId } = action.payload;

      state.quotation.areas[areaId].items = state.quotation.areas[areaId].items.filter(
        (id) => id !== itemId
      );

      state.quotation.items = omit(state.quotation.items, itemId);
    },

    // UPDATE AREA
    updateAreaSuccess(state, action) {
      const area = action.payload;

      state.isLoading = false;

      state.quotation.areas = area;
    },

    //  DELETE AREA
    deleteAreaSuccess(state, action) {
      const { areaId } = action.payload;
      const deletedArea = state.quotation.areas[areaId];

      state.isLoading = false;
      state.quotation.areas = omit(state.quotation.areas, [areaId]);
      state.quotation.items = omit(state.quotation.items, [...deletedArea.itemIds]);
      state.quotation.areaOrder = state.quotation.areaOrder.filter((id) => id !== areaId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getQuotation(quotationItems) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const hadSelectedArea = [];
      quotationItems.forEach((item) => {
        const idx = hadSelectedArea.map((area) => area.area).findIndex((a) => a === item.area);
        if (idx === -1) {
          hadSelectedArea.push({
            area: item.area,
            engineering: new Set([item.engineering_project]),
          });
        } else {
          hadSelectedArea[idx].engineering.add(item.engineering_project);
        }
      });

      const fixedItems = quotationItems.map((item) => {
        item.id = uuidv4();
        return item;
      });

      const fixedAreas = hadSelectedArea.map((item) => {
        item.id = uuidv4();
        item.engineering = Array.from(item.engineering);
        item.itemIds = fixedItems.filter((i) => i.area === item.area).map((i) => i.id);
        return item;
      });

      const areaOrder = fixedAreas.map((item, index) => item.id);

      dispatch(
        slice.actions.getQuotationSuccess({
          areas: fixedAreas,
          items: fixedItems,
          areaOrder,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistArea(newColumnOrder) {
  return (dispatch) => {
    dispatch(slice.actions.persistArea(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistItem(columns) {
  return (dispatch) => {
    dispatch(slice.actions.persistItem(columns));
  };
}

// ----------------------------------------------------------------------
