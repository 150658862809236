import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));

// REPORT
export const ReportSalesPage = Loadable(lazy(() => import('../pages/dashboard/ReportSalesPage')));

// DASHBOARD: BRANCH
export const BranchListPage = Loadable(lazy(() => import('../pages/dashboard/BranchListPage')));
// export const BranchCreatePage = Loadable(lazy(() => import('../pages/dashboard/BranchCreatePage')));
// export const BranchEditPage = Loadable(lazy(() => import('../pages/dashboard/BranchEditPage')));
export const BranchProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/BranchProfilePage'))
);

// DASHBOARD: EMPLOYEE
export const EmployeeProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/EmployeeProfilePage'))
);
export const EmployeeListPage = Loadable(lazy(() => import('../pages/dashboard/EmployeeListPage')));
export const EmployeeCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/EmployeeCreatePage'))
);
export const EmployeeEditPage = Loadable(lazy(() => import('../pages/dashboard/EmployeeEditPage')));

// DASHBOARD: CUSTOMER
export const CustomerProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/CustomerProfilePage'))
);
export const CustomerListPage = Loadable(lazy(() => import('../pages/dashboard/CustomerListPage')));
export const CustomerCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/CustomerCreatePage'))
);
export const CustomerEditPage = Loadable(lazy(() => import('../pages/dashboard/CustomerEditPage')));
export const CustomerClassPage = Loadable(
  lazy(() => import('../pages/dashboard/CustomerClassPage'))
);

// DASHBOARD: PROJECT
export const ProjectListPage = Loadable(lazy(() => import('../pages/dashboard/ProjectListPage')));
export const ProjectEditPage = Loadable(lazy(() => import('../pages/dashboard/ProjectEditPage')));
export const ProjectCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/ProjectCreatePage'))
);
export const ProjectProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/ProjectProfilePage'))
);

// DASHBOARD: QUOTATION

export const QuotationListPage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationListPage'))
);
export const QuotationCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationCreatePage'))
);
export const QuotationEditPage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationEditPage'))
);
export const QuotationDuplicatePage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationDuplicatePage'))
);
export const QuotationProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationProfilePage'))
);

export const QuotationVariantCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationVariantCreatePage'))
);
export const QuotationVariantEditPage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationVariantEditPage'))
);

export const QuotationApprovingPage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationApprovingPage'))
);

export const EngineeringQuotationItemPage = Loadable(
  lazy(() => import('../pages/dashboard/EngineeringQuotationItemPage'))
);

export const QuotationTermsAndConditionsSettingEPage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationTermsAndConditionsSettingEPage'))
);
export const QuotationTermsAndConditionsSettingSPage = Loadable(
  lazy(() => import('../pages/dashboard/QuotationTermsAndConditionsSettingSPage'))
);

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const NotSupportPage = Loadable(lazy(() => import('../pages/NotSupportPage')));
