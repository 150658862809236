// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const tw = {
  demo: {
    title: `Chinese`,
  },
  docs: {
    hi: `你好`,
    description: `需要幫忙？\n 請查看我們的文件.`,
    documentation: `文件`,
  },
  app: `應用程式`,
  user: `使用者`,
  employee: `員工`,
  customer: `客戶`,
  project: `案場`,
  quotation: `報價單`,
  list: `列表`,
  edit: `編輯`,
  'class list': `分類表`,
  'engineering quotation item': `工程報價項目`,
  'engineering quotation terms and conditions setting': `工程服務條款設定`,
  'selling quotation terms and conditions setting': `買賣服務條款設定`,
  management: `管理`,
  general: `一般`,
  report: `報表`,
  branch: `分店`,
};

export default tw;
