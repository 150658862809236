import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function NotSupportPage() {
  return (
    <>
      <Helmet>
        <title> Not Support | IBIZA </title>
      </Helmet>

      <Stack
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}
      >
        <Typography variant="h6">Please use desktop to get a better experience.</Typography>

        <Typography sx={{ color: 'text.secondary' }}>Mobile version is not available.</Typography>

        {/* <Button component={RouterLink} to="/" size="large" variant="contained">
          Go to Home
        </Button> */}
      </Stack>
    </>
  );
}
