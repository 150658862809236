// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Label from '../../../components/label';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  file: icon('ic_file'),
  store: icon('ic_tour'),
  quotation: icon('ic_invoice'),
  project: icon('ic_job'),
  customer: icon('ic_customer'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // { title: 'IBIZA', path: PATH_DASHBOARD.one, icon: ICONS.dashboard },
      {
        title: 'branch',
        path: PATH_DASHBOARD.branch.root,
        icon: ICONS.store,
        roles: ['branch store manager', 'branch store viewer'],
      },
      // EMPLOYEE
      {
        title: 'employee',
        path: PATH_DASHBOARD.employee.root,
        icon: ICONS.user,
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.employee.list,
            roles: ['employee manager', 'employee viewer'],
          },
        ],
        roles: ['employee manager', 'employee viewer'],
      },
    ],
  },
  // REPORT
  // ----------------------------------------------------------------------
  {
    subheader: 'report',
    items: [
      // { title: 'IBIZA', path: PATH_DASHBOARD.one, icon: ICONS.dashboard },
      {
        title: '業務報表',
        path: PATH_DASHBOARD.report.sales,
        icon: ICONS.analytics,

        roles: ['report manager', 'report viewer'],
      },
    ],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // CUSTOMER
      {
        title: 'customer',
        path: PATH_DASHBOARD.customer.root,
        icon: ICONS.customer,
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.customer.list,
            roles: ['customer manager', 'customer viewer', 'customer operator'],
          },
          {
            title: 'class list',
            path: PATH_DASHBOARD.customer.class,
            roles: ['customer classification manager'],
          },
        ],
        roles: [
          'customer manager',
          'customer viewer',
          'customer operator',
          'customer classification manager',
          'customer classification viewer',
        ],
      },
      // PROJECT
      {
        title: 'project',
        path: PATH_DASHBOARD.project.root,
        icon: ICONS.project,
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.project.list,
            roles: [
              'project manager',
              'project manager (cannot change sales)',
              'project viewer',
              'project operator',
            ],
          },
        ],
        roles: [
          'project manager',
          'project manager (cannot change sales)',
          'project viewer',
          'project operator',
        ],
      },
      // QUOTATION
      {
        title: 'quotation',
        path: PATH_DASHBOARD.quotation.root,
        icon: ICONS.quotation,
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.quotation.list,
            roles: ['quotation manager', 'quotation viewer', 'quotation operator'],
          },
          {
            title: 'engineering quotation item',
            path: PATH_DASHBOARD.quotation.engineeringQuotationItem,
            roles: ['default quotation item manager', 'default quotation item viewer'],
          },
          {
            title: 'engineering quotation terms and conditions setting',
            path: PATH_DASHBOARD.quotation.quotationTermsAndConditionSettingE,
            roles: ['quotation service term manager', 'quotation service term viewer'],
          },
          {
            title: 'selling quotation terms and conditions setting',
            path: PATH_DASHBOARD.quotation.quotationTermsAndConditionSettingS,
            roles: ['quotation service term manager', 'quotation service term viewer'],
          },
        ],
        roles: [
          'quotation manager',
          'quotation viewer',
          'quotation operator',
          'default quotation item manager',
          'default quotation item viewer',
          'quotation service term manager',
          'quotation service term viewer',
        ],
      },
    ],
  },
];

export default navConfig;
