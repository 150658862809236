// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: '/',
  login: '/login',
  register: '/register',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  // pricing: '/pricing',
  // payment: '/payment',
  // about: '/about-us',
  // contact: '/contact-us',
  // faqs: '/faqs',
  notSupport: '/not-support',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, '/one'),
  two: path(ROOTS_DASHBOARD, '/two'),
  three: path(ROOTS_DASHBOARD, '/three'),
  branch: {
    root: path(ROOTS_DASHBOARD, '/branch'),
    list: path(ROOTS_DASHBOARD, '/branch/list'),
    create: path(ROOTS_DASHBOARD, '/branch/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/branch/${id}/edit`),
    profile: (id) => path(ROOTS_DASHBOARD, `/branch/${id}/profile`),
  },
  report: {
    // root: path(ROOTS_DASHBOARD, '/report'),
    sales: path(ROOTS_DASHBOARD, '/report/sales'),
  },
  employee: {
    root: path(ROOTS_DASHBOARD, '/employee'),
    list: path(ROOTS_DASHBOARD, '/employee/list'),
    create: path(ROOTS_DASHBOARD, '/employee/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/employee/${id}/edit`),
    profile: (id) => path(ROOTS_DASHBOARD, `/employee/${id}/profile`),
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    create: path(ROOTS_DASHBOARD, '/customer/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/customer/${id}/edit`),
    profile: (id) => path(ROOTS_DASHBOARD, `/customer/${id}/profile`),

    class: path(ROOTS_DASHBOARD, '/customer/class'),
  },
  project: {
    root: path(ROOTS_DASHBOARD, '/project'),
    list: path(ROOTS_DASHBOARD, '/project/list'),
    create: path(ROOTS_DASHBOARD, '/project/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/project/${id}/edit`),
    profile: (id) => path(ROOTS_DASHBOARD, `/project/${id}/profile`),
  },
  quotation: {
    root: path(ROOTS_DASHBOARD, '/quotation'),
    list: path(ROOTS_DASHBOARD, '/quotation/list'),
    create: path(ROOTS_DASHBOARD, '/quotation/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/quotation/${id}/edit`),
    duplicate: (id) => path(ROOTS_DASHBOARD, `/quotation/${id}/duplicate`),
    profile: (id) => path(ROOTS_DASHBOARD, `/quotation/${id}/profile`),

    variantCreate: (id) => path(ROOTS_DASHBOARD, `/quotation/${id}/variant/create`),
    variantEdit: (id, variant_code) =>
      path(ROOTS_DASHBOARD, `/quotation/${id}/variant/${variant_code}/edit`),

    approving: (id) => path(ROOTS_DASHBOARD, `/quotation/quotation-approving/${id}`),

    engineeringQuotationItem: path(ROOTS_DASHBOARD, '/quotation/engineering-quotation-item'),
    quotationTermsAndConditionSettingE: path(
      ROOTS_DASHBOARD,
      '/quotation/quotation-terms-and-condition-setting-e'
    ),
    quotationTermsAndConditionSettingS: path(
      ROOTS_DASHBOARD,
      '/quotation/quotation-terms-and-condition-setting-s'
    ),
  },
};
