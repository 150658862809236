import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  Page404,
  Page403,
  Page500,
  PageOne,
  NotSupportPage,
  // Dashboard: Branch
  BranchListPage,
  // BranchCreatePage,
  // BranchEditPage,
  BranchProfilePage,
  // Report: Sales
  ReportSalesPage,
  // Dashboard: Employee
  EmployeeListPage,
  EmployeeCreatePage,
  EmployeeEditPage,
  EmployeeProfilePage,
  // Dashboard: Customer
  CustomerListPage,
  CustomerCreatePage,
  CustomerEditPage,
  CustomerProfilePage,
  CustomerClassPage,
  // Dashboard: PROJECT
  ProjectListPage,
  ProjectCreatePage,
  ProjectEditPage,
  ProjectProfilePage,
  // Dashboard: QUOTATION
  QuotationListPage,
  QuotationCreatePage,
  QuotationEditPage,
  QuotationDuplicatePage,
  QuotationProfilePage,
  QuotationVariantCreatePage,
  QuotationVariantEditPage,
  QuotationApprovingPage,
  EngineeringQuotationItemPage,
  QuotationTermsAndConditionsSettingEPage,
  QuotationTermsAndConditionsSettingSPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'one', element: <PageOne /> },
        { path: 'report', children: [{ path: 'sales', element: <ReportSalesPage /> }] },
        {
          path: 'branch',
          children: [
            { element: <Navigate to="/dashboard/branch/list" replace />, index: true },
            { path: 'list', element: <BranchListPage /> },
            // { path: 'create', element: <BranchListPage /> },
            // { path: ':id/edit', element: <BranchListPage /> },
            { path: ':id/profile', element: <BranchProfilePage /> },
          ],
        },
        {
          path: 'employee',
          children: [
            { element: <Navigate to="/dashboard/employee/list" replace />, index: true },
            { path: 'list', element: <EmployeeListPage /> },
            { path: 'create', element: <EmployeeCreatePage /> },
            { path: ':id/edit', element: <EmployeeEditPage /> },
            { path: ':id/profile', element: <EmployeeProfilePage /> },
          ],
        },
        {
          path: 'customer',
          children: [
            { element: <Navigate to="/dashboard/customer/list" replace />, index: true },
            { path: 'list', element: <CustomerListPage /> },
            { path: 'create', element: <CustomerCreatePage /> },
            { path: ':id/edit', element: <CustomerEditPage /> },
            { path: ':id/profile', element: <CustomerProfilePage /> },
            { path: 'class', element: <CustomerClassPage /> },
          ],
        },
        {
          path: 'project',
          children: [
            { element: <Navigate to="/dashboard/project/list" replace />, index: true },
            { path: 'list', element: <ProjectListPage /> },
            { path: 'create', element: <ProjectCreatePage /> },
            { path: ':id/edit', element: <ProjectEditPage /> },
            { path: ':id/profile', element: <ProjectProfilePage /> },
          ],
        },
        {
          path: 'quotation',
          children: [
            { element: <Navigate to="/dashboard/quotation/list" replace />, index: true },
            { path: 'list', element: <QuotationListPage /> },
            { path: 'create', element: <QuotationCreatePage /> },
            { path: ':id/edit', element: <QuotationEditPage /> },
            { path: ':id/duplicate', element: <QuotationDuplicatePage /> },
            { path: ':id/profile', element: <QuotationProfilePage /> },

            { path: ':id/variant/create', element: <QuotationVariantCreatePage /> },
            { path: ':id/variant/:variant_code/edit', element: <QuotationVariantEditPage /> },

            { path: 'approving/:id', element: <QuotationApprovingPage /> },
            { path: 'engineering-quotation-item', element: <EngineeringQuotationItemPage /> },
            {
              path: 'quotation-terms-and-condition-setting-e',
              element: <QuotationTermsAndConditionsSettingEPage />,
            },
            {
              path: 'quotation-terms-and-condition-setting-s',
              element: <QuotationTermsAndConditionsSettingSPage />,
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: 'not-support', element: <NotSupportPage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
